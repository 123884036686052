<template>
    <div id="association">
		<v-head currentIndex=5></v-head>
        <img src="../../../assets/imgs/pc/association_activity/img_banner@2x.png" class="header" alt="國醫薈萃，心享健康">
        <div class="content">
            <div class="association-list-wrapper">
                <div class="association-list">
                    <div class="association-item" v-for="association in associationList" :key="association.associationid">
                        <img class="association-logo" :src="association.logourl" :alt="association.name">
                        <a class="btn-association-link" :href="association.domain" target="_blank">學會網站</a>
                        <a class="btn-join-association" :href="`${association.domain}/Association/JoinUs`" target="_blank">申請加入學會</a>
                    </div>
                    <div class="association-more">
                        <div class="title">敬請期待更多學會加入</div>
                        <router-link to="/home/contact" class="desc">聯絡我們</router-link>
                    </div>
                </div>
            </div>
            <div class="activity-wrapper">
                <div class="activity-container">
                    <div class="activity-filter">
                        <span class="filter-hint">篩選學會:</span>
                        <a-dropdown :trigger="['click']">
                            <div class="filter-value" :data-value="associationId">{{associationName || "全部學會"}}</div>
                            <a-menu slot="overlay">
                                <a-menu-item @click="onAssociationItemClick('', '')" key="-1">全部學會</a-menu-item>
                                <a-menu-divider />
                                <a-menu-item @click="onAssociationItemClick(association.name, association.associationid)" :key="association.associationid" v-for="association in associationList">
                                    {{association.name}}
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </div>
                    <div class="activity-sortby">
                        <span class="sortby-hint">學會課程：</span>
                        <ul class="activity-sortby-items">
                            <li :class="{'activity-sortby-item': true, 'current': tabIndex == 0}" @click="changeTabIndex(0)">所有課程</li>
                            <li :class="{'activity-sortby-item': true, 'current': tabIndex == 1}" @click="changeTabIndex(1)">未進行</li>
                            <li :class="{'activity-sortby-item': true, 'current': tabIndex == 2}" @click="changeTabIndex(2)">進行中</li>
                            <li :class="{'activity-sortby-item': true, 'current': tabIndex == 3}" @click="changeTabIndex(3)">已結束</li>
                        </ul>
                    </div>
                    <div class="activity-list">
                        <div :class="{'activity-item': true, [activity.step]: true}" v-for="activity in activityList" :key="activity.activityid">
                            <div class="activity-pic-wrapper">
                                <img :src="activity.coverpicurl" alt="" :class="{'activity-pic': true, 'hide': !activity.coverpicurl}">
                            </div>
                            <div class="activity-item-r">
                                <h2 class="title"><a :href="activity.activitydetailurl" v-text="activity.title"></a> </h2>
                                <div class="activity-info">
                                    <div class="activity-c-time">
                                        <span class="time-h">活動日期:</span>
                                        <span class="time-v" v-text="activity.starttime + '至' +activity.endtime"></span>
                                    </div>
                                    <span class="association-name" v-text="activity.associationname"></span>
                                </div>
                                <p class="content" v-text="activity.introduce"></p>
                            </div>
                            <div class="btn-status" v-text="activity.statusText"></div>
                        </div>
                    </div>
                    <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
                    <loading v-if="loadingActivity"/>
                </div>
            </div>
            <loading v-if="loadingAssociation" class="loading-box-1"/>
        </div>
        <join-association-success v-if="joinVisible" :callback="onClose" />
        <v-foot></v-foot>
    </div>
	
</template>

<script>
    import association from '@/views/mixins/association'
	import vHead from '@/layouts/Head.vue'
	import vFoot from '@/layouts/Foot.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
    import Loading from '@/layouts/Loading.vue'
    import JoinAssociationSuccess from '@/layouts/JoinAssociationSuccess.vue'
	export default {
        mixins: [
            association,
        ],
        components: {
			vHead,
			vFoot,
            Loading,
            SplitPage,
            JoinAssociationSuccess,
        },
        data(){
            return {
                style: '',
                visible: false,
                joinVisible: false,
                tabIndex: 0,
                associationId: '',
                associationName: '',
                associationList: [],
                activityList: [],
                loadingActivity: true,
                loadingAssociation: true,
                pageNo: 1,
                pageSize: 10,
                total: 0,
            }
        },
        mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            this.loadAssociationList((rs, error)=>{
                if(!error) {
                    this.associationList = rs || []
                } else {
                    this._toast.warning(this, error)
                }
                this.loadingAssociation = false
                this.onActivityRefresh()
            })
        },
        methods: {
            onActivityRefresh: function(){
                this.loadingActivity = true
                this.loadActivityList({
                    langcode: 'GB',
                    associationid: this.associationId || undefined,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    type: this.tabIndex || undefined,
                }, (data, error)=>{
                    this.activityList = data.list
                    this.total = data.total
                    this.visible = this.total > 0
                    this.loadingActivity = false
                })
            },
            onPageChanged: function(pageNo){
                this.$router.push({path: '/Activity/#page='+pageNo},(e)=>{});
                if(this.pageNo != pageNo) {
                    this.pageNo = pageNo
                    this.onActivityRefresh()
                }
            },
            onAssociationItemClick: function(name, value){
                if(this.associationId != value) {
                    this.associationName = name
                    this.associationId = value
                    this.pageNo = 1
                    this.$router.push({path: '/Activity/'},(e)=>{});
                    this.visible = false
                    this.onActivityRefresh()
                }
            },
            joinAssociation: function(id) {
				this.joinVisible = true
				this.style = document.body.getAttribute('style') || ''
                document.body.setAttribute('style', this.style + 'overflow: hidden;')
            },
            onClose: function(){
				this.joinVisible = false
				document.body.setAttribute('style', this.style)
            },
            changeTabIndex: function(tabIndex) {
                this.tabIndex = tabIndex
                this.visible = false
                this.pageNo = 1
                this.onActivityRefresh()
            }
        }
    }
</script>

<style lang="scss" scoped>
    #association {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    #association .loading-box-1 {
        padding-top: 84px;
    }
    
    #association  .content {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    
    #association .header {
        width: 100%;
        max-height: 234px;
        vertical-align: top;
        object-fit: cover;
    }

    #association .association-list-wrapper {
        width: 1000px;
        overflow-x: scroll;
        height: 148px;
        margin: 50px auto;
        overflow-y: hidden;
        position: relative;
    }

    //隐藏滚动条
    /* Hide scrollbar for Chrome, Safari and Opera */
    #association .association-list-wrapper::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    #association  .association-list-wrapper {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    #association .association-list {
        height: 148px;
        width: 99999px;
    }

    #association .association-list > .association-item {
        width: 327px;
        height: 148px;
        background-color: #fff;
        margin-right: 10px;
        position: relative;
        float: left;
    }

    #association .association-list > .association-item > .association-logo {
        width: 258px;
        height: 48px;
        display: inline-block;
        margin: 19px 34px 39px 36px;
    }

    #association .association-list > .association-item > .btn-association-link {
        width: 96px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #FF8000;
        font-weight: bold;
        font-size: 16px;
        border-radius: 4px;
        background-color: #FFE3D5;
        position: absolute;
        left: 35px;
        bottom: 16px;
        cursor: pointer;
    }

    #association .association-list > .association-item > .btn-association-link:hover {
        opacity: .9;
    }

    #association .association-list > .association-item > .btn-join-association {
        width: 130px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #36C4D0;
        font-weight: bold;
        font-size: 16px;
        border-radius: 4px;
        background-color: #DCF7FB;
        position: absolute;
        right: 34px;
        bottom: 16px;
        cursor: pointer;
    }

    #association .association-list > .association-item > .btn-join-association:hover {
        opacity: .9;
    }

    #association .association-list > .association-more {
        width: 327px;
        height: 148px;
        background-image: url(../../../assets/imgs/pc/association_activity/img_qidai@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: inline-block;
    }

    #association .association-list > .association-more > .title {
        font-size: 18px;
        line-height: 18px;
        font-weight: bold;
        color: #fff;
        margin-top: 51px;
        margin-left: 82px;
        margin-bottom: 27px;    
    }

    #association .association-list > .association-more > .desc {
        color: #fff;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
    }

    #association .association-list > .association-more > .desc > a {
        text-decoration: underline;
        margin-left: 8px;
        cursor: initial;
    }

    /* 學會活動列表 start */
    #association .activity-wrapper {
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
    }

    #association .activity-container {
        width: 1000px;
        margin: 50px auto 0px auto;
        padding-bottom: 60px;
        position: relative;
        min-height: 400px;
        box-sizing: border-box;
    }

    #association .activity-container .activity-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
    }

    #association .activity-container .activity-filter .filter-hint {
        color: #969696;
        font-size: 16px;
        height: 15px;
        line-height: 15px;
        font-weight: 400;
        display: inline-block;
        margin-right: 20px;
    }

    #association .activity-container .activity-filter .filter-value {
        display: inline-block;
        width: 320px;
        height: 36px;
        line-height: 36px;
        font-weight: bold;
        color: #231F20;
        font-size: 18px;
        box-sizing: border-box;
        padding-left: 27px;
        border: 1px solid #E8E8E8;
        position: relative;
        cursor: pointer;
    }

    #association .activity-container .activity-filter .filter-value[data-value=''] {
        color: #969696;
        font-weight: 400;
    }

    #association .activity-container .activity-filter .filter-value::before {
        content: '';
        position: absolute;
        right: 0px;
        top: 0px;
        width: 36px;
        height: 36px;
        background-color: #E8E8E8;
    }

    #association .activity-container .activity-filter .filter-value::after {
        content: '';
        position: absolute;
        right: 11px;
        top: 14px;
        width: 13px;
        height: 9px;
        background-image: url(../../../assets/imgs/pc/association_activity/icon_xial@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #association .activity-container .activity-sortby {
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #association .activity-container .activity-sortby .sortby-hint {
        color: #969696;
        font-size: 16px;
        height: 15px;
        line-height: 15px;
        font-weight: 400;
        display: inline-block;
        margin-right: 20px;
    }

    #association .activity-container .activity-sortby .activity-sortby-items {
        display: flex;
        flex-direction: row;
        flex: 1;
        border-bottom: 1px solid #E8E8E8;
        box-sizing: border-box;
    }

    #association .activity-container .activity-sortby .activity-sortby-items > .activity-sortby-item {
        display: inline-block;
        width: 124px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        color: #231F20;
        cursor: pointer;
    }

    #association .activity-container .activity-sortby .activity-sortby-items > .activity-sortby-item.current {
        box-sizing: border-box;
        border: 1px solid #E8E8E8;
        border-bottom: 0px;
        color: #231F20;
        font-weight: bold;
    }

    #association .activity-container .activity-list {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }

    #association .activity-container .activity-list > .activity-item {
        display: flex;
        flex-direction: row;
        position: relative;
        background-color: #fff;
        margin-bottom: 20px;
    }

    #association .activity-container .activity-list > .activity-item:last-child {
        margin-bottom: 0px;
    }

    #association .activity-container .activity-list > .activity-item.step2 {
        background-color: #F4F4F4;
    }

    #association .activity-container .activity-list > .activity-item.step2::after {
        content: '';
        position: absolute;
        left: 60px;
        top: 27px;
        width: 141px;
        height: 124px;
        background-image: url(../../../assets/imgs/pc/association_activity/img_wanjie@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #association .activity-container .activity-list > .activity-item > .activity-pic-wrapper {
        width: 260px;
        height: 178px;
        margin-right: 30px;
        background-color: #ddd;
        align-self: center;
    }

    #association .activity-container .activity-list > .activity-item > .activity-pic-wrapper > .activity-pic {
        width: 100%;
        height: 100%;
    }
    #association .activity-container .activity-list > .activity-item > .activity-pic-wrapper > .activity-pic.hide {
        display: none;
    }

    #association .activity-container .activity-list > .activity-item > .activity-item-r {
        display: flex;
        flex-direction: column;
    }

    #association .activity-container .activity-list > .activity-item .title {
        height: 19px;
        line-height: 19px;
        font-size: 18px;
        font-weight: bold;
        color: #231F20;
        margin-top: 22px;
    }

    #association .activity-container .activity-list > .activity-item.step2 .title {
        color: #636363;
    }

    #association .activity-container .activity-list > .activity-item .activity-info {
        display: flex;
        flex-direction: row;
        margin-top: 13px;
        margin-bottom: 20px;
    }

    #association .activity-container .activity-list > .activity-item .activity-info > .association-name {
        color: #969696;
        font-size: 14px;
        font-weight: 400;
        line-height: 13px;
    }

    #association .activity-container .activity-list > .activity-item .activity-info .activity-c-time {
        display: flex;
        flex-direction: row;
        margin-right: 31px;
    }

    #association .activity-container .activity-list > .activity-item .activity-info .activity-c-time > .time-h {
        color: #969696;
        font-size: 14px;
        font-weight: 400;
        line-height: 13px;
    }

    #association .activity-container .activity-list > .activity-item .activity-info .activity-c-time >  .time-v {
        color: #969696;
        font-size: 14px;
        font-weight: 400;
        margin-left: 8px;
        line-height: 13px;
    }

    #association .activity-container .activity-list > .activity-item .content {
        font-size: 14px;
        font-weight: 400;
        color: #636363;
        line-height: 21px;
        display: inline-block;
        width: 690px;
        margin-bottom: 12px;
    }

    #association .activity-container .activity-list > .activity-item .btn-status {
        position: absolute;
        right: 20px;
        top: 23px;
        width: 66px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        font-weight: bold;
        color: #FF8000;
        background-color: #FFE3D5;
        border-radius: 11px;
        font-size: 14px;
    }

    #association .activity-container .activity-list > .activity-item.step0 .btn-status {
        color: #FF8000;
        background-color: #FFE3D5;
    }

    #association .activity-container .activity-list > .activity-item.step1 .btn-status {
        color: #36C4D0;
        background-color: #DCF7FB;
    }

    #association .activity-container .activity-list > .activity-item.step2 .btn-status {
        color: #231F20;
        background-color: #E8E8E8;
    }
    /* 學會活動列表 end */

    /* 分页 start */

    .activity-split-pages {
        display: flex;
        flex-direction: row;
        margin-top: 81px;
        justify-content: center;
    }

    .activity-split-pages > .split-pages {
        display: flex;
        flex-direction: row;
    }

    .activity-split-pages > .split-pages > .split-page {
        display: inline-block;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        color: #969696;
        font-size: 18px;
        line-height: 33px;
        text-align: center;
        font-weight: 400;
        margin-right: 24px;
        background-color: #DDDDDD;
        cursor: pointer;
    }

    .activity-split-pages > .split-pages > .split-page.more {
        line-height: 25px;
    }

    .activity-split-pages > .split-pages > .split-page:hover,
    .activity-split-pages > .split-pages > .split-page.current {
        color: #fff;
        background-color: #36C4D0;
        font-weight: bold;
    }

    .activity-split-pages > .btn-page-prev,
    .activity-split-pages > .btn-page-next {
        height: 30px;
        width: 73px;
        text-align: center;
        line-height: 30px;
        font-weight: 400;
        color: #636363;
        background-color: #DDDDDD;
        border-radius: 8px;
        cursor: pointer;
    }
    .activity-split-pages > .btn-page-next {
        margin-left: 24px;
        color: #231F20;
    }
    /* 分页 end */
</style>

