export default {
    methods: {
        loadAssociationList(callback) {
            this.$store.dispatch('getAssociationList', {})
            .then((rs) => {
                callback && callback(rs, null)
            }).catch((rs) => {
                callback && callback(null, rs)
            })
        },
        loadActivityList(data, callback) {
            this.$store.dispatch('getActivityList', data)
            .then((rs) => {
                var list = rs.list || []
                var total = rs.total || 0
                list.forEach((data)=>{
                    var start = data.starttime && new Date(data.starttime.replace(/-/g, '/')).getTime()
                    var end = data.endtime && new Date(data.endtime.replace(/-/g, '/')).getTime()
                    var now = Date.now()
                    if(now < start) {
                        data.step = 'step0'
                        data.statusText = '未進行'
                    } else if(now > end) {
                        data.step = 'step2'
                        data.statusText = '已結束'
                    } else {
                        data.step = 'step1'
                        data.statusText = '進行中'
                    }
                })
                callback && callback({list, total}, null)
            }).catch((rs) => {
                callback && callback(null, rs)
            })
        },
    }
}