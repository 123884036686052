<template>
    <div class="dialog" @click.self="dismissDialog">
        <div class="dialog-container">
            <div class="title-bar">
                <span class="title">加入學會申請</span>
                <span class="close-dialog" @click="dismissDialog"></span>
            </div>
            <span class="icon-ok"></span>
            <span class="success-title">恭喜您，申請成功，請等待審核！</span>
            <span class="success-desc">您已成功提交加入學會申請,請耐心等待相關人員審核</span>
            <div class="btn-ok" @click="dismissDialog">好的，我知道啦</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        callback: Function,
        title: '',
    },
    data() {
        return {
        }
    },
    mounted: function(){
    },
    methods: {
        dismissDialog: function() {
            this.callback && this.callback()
        },
        toNext: function() {
            this.translateX = -936
            this.pageIndex = -1
            setTimeout(() => {
                this.pageIndex = 1
            }, 500);
        },
        toPrev: function() {
            this.translateX = 0
             this.pageIndex = -1
            setTimeout(() => {
                this.pageIndex = 0
            }, 500);
        }
    }
}
</script>

<style scoped>
    .dialog {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1000;
        background-color: rgba(4, 58, 61, 0.3);
        overflow: hidden;
    }
    .dialog > .dialog-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 936px;
        height: 600px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .dialog> .dialog-container .title-bar {
        display: inline-block;
        height: 46px;
        line-height: 46px;
        background-color: #2B99A6;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
    }

    .dialog > .dialog-container .title-bar .close-dialog {
        float: right;
        width: 46px;
        height: 46px;
        position: relative;
        background-color: #36C4D0;
        cursor: pointer;
    }

    .dialog > .dialog-container .title-bar .close-dialog::after {
        width: 20px;
        height: 20px;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url(../assets/imgs/pc/img_cha_white@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .btn-ok {
        position: absolute;
        left: 50%;
        top: 428px;
        width: 282px;
        height: 42px;
        border-radius: 21px;
        background-color: #01B1BC;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        line-height: 42px;
        text-align: center;
        transform: translateX(-50%);
        cursor: pointer;
        user-select: none;
    }

    .btn-ok:active {
        opacity: .7;
    }

    .icon-ok {
        position: absolute;
        left: 98px;
        top: 133px;
        width: 87px;
        height: 87px;
        background-image: url(../assets/imgs/pc/association_activity/img_gouzi@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .success-title {
        font-size: 24px;
        line-height: 24px;
        color: #FF840C;
        height: 24px;
        position: absolute;
        left: 225px;
        top: 133px;
        font-weight: bold;
    }

    .success-desc {
        font-size: 18px;
        line-height: 19px;
        color: #333333;
        height: 24px;
        position: absolute;
        left: 225px;
        top: 182px;
        font-weight: 400;
    }
</style>